import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

export default function MainContent() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          aboutUsPage {
            mainContent {
              ambition {
                text
                title
              }
              founders {
                title
                items {
                  image
                  alt
                  name
                  title
                }
              }
              whoWeAre {
                text
                title
              }
            }
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "pages/about_us"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 80)
            }
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.aboutUsPage.mainContent;
  return (
    <div className="content-padding font-light">
      <div className="pb-10">
        <h1 className="section-title pb-6">{metadata.ambition.title}</h1>
        <div dangerouslySetInnerHTML={{__html: metadata.ambition.text}} />
      </div>
      <div className="pb-10">
        <h1 className="section-title pb-6">{metadata.whoWeAre.title}</h1>
        <div dangerouslySetInnerHTML={{__html: metadata.whoWeAre.text}} />
      </div>
      <div className="pb-10">
        <h1 className="section-title pb-6">{metadata.founders.title}</h1>
        <div className="flex flex-col md:flex-row items-center space-y-8 md:space-x-8 md:space-y-0 justify-center">
          {metadata.founders.items.map((founder) => (
            <div className="flext flex-col items-center">
              <div className="rounded-2xl mb-3">
                <GatsbyImage
                  alt={founder.alt}
                  image={
                    data.allFile.edges.find(
                      (el) => el.node.name === founder.image
                    ).node.childImageSharp.gatsbyImageData
                  }
                  className="rounded-2xl"
                />
              </div>
              <div className="font-medium">{founder.name}</div>
              <div>{founder.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
