import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import ResponsiveGatsbyImage from "src/components/common/responsive_image";
import FullScreenImageWithText from "src/components/common/fullscreen_image_with_text";

export default function Hero() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          aboutUsPage {
            hero {
              alt
              image
              title
            }
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "pages/about_us"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 60)
            }
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.aboutUsPage.hero;
  return (
    <FullScreenImageWithText
      image_alt={metadata.alt}
      files={data.allFile.edges}
      image_name={metadata.image}
      title={metadata.title}
      style="light"
    />
  );
}
