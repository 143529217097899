import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ResponsiveGatsbyImage from "src/components/common/responsive_image"

export default function BottomBanner() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          aboutUsPage {
            bottomBanner {
              coverText
              alt
              image
            }
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "pages/about_us" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: { fit: COVER }, quality: 80)
            }
          }
        }
      }
    }
  `)

  const metadata = data.site.siteMetadata.aboutUsPage.bottomBanner
  return (
    <div className="overflow-hidden">
      <div className="relative">
        <ResponsiveGatsbyImage
          alt={metadata.alt}
          files={data.allFile.edges}
          image={metadata.image}
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-gray-700 text-xl lg:text-3xl ">
            {metadata.coverText}
          </span>
        </div>
      </div>
    </div>
  )
}
