import React, { useState } from "react"
import Header from "src/components/common/header"
import CommonHelmet from "src/components/common/helmet"
import Footer from "src/components/common/footer"
import Hero from "src/components/pages/about_us/hero"
import MainContent from "src/components/pages/about_us/main_content"
import BottomBanner from "src/components/pages/about_us/bottom_banner"

export default function AboutUsPage() {
  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <div className="max-w-7xl relative">
        <Header heroStyle="light" />
        <CommonHelmet />
        <Hero />
        <MainContent />
        <BottomBanner />
        <Footer />
      </div>
    </main>
  )
}
